@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
.hero-landing-block {
  position: relative;
  background: #0f1b2a;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 608px;
  overflow: hidden;
  background-color: #061426;
}
.hero-landing-block video {
  position: absolute;
  right: -45%;
  top: 0;
  width: auto;
  height: 100%;
  aspect-ratio: 1512/720;
}
@media only screen and (min-width: 1025px) {
  .hero-landing-block video {
    right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .hero-landing-block {
    height: 720px;
  }
}
.hero-landing-block .container {
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 24px;
  position: relative;
  padding: 0 24px;
}
@media only screen and (min-width: 768px) {
  .hero-landing-block .container {
    padding: 0 32px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-landing-block .container {
    padding: 0 48px;
  }
}
@media only screen and (min-width: 768px) {
  .hero-landing-block .container {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-landing-block .container {
    padding: 0 80px;
  }
}
.hero-landing-block__intro {
  max-width: 800px;
}
.hero-landing-block__heading {
  font-weight: 700;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 48px;
}
@media only screen and (min-width: 768px) {
  .hero-landing-block__heading {
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1025px) {
  .hero-landing-block__heading {
    font-size: 4rem;
  }
}
.hero-landing-block__sub-heading {
  color: #3beffb;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 100%;
  font-style: italic;
  margin-bottom: 24px;
  font-size: 2rem;
}
@media only screen and (min-width: 768px) {
  .hero-landing-block__sub-heading {
    font-size: 2.5rem;
  }
}
.hero-landing-block__description {
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 40px;
  font-size: 0.875rem;
}
@media only screen and (min-width: 768px) {
  .hero-landing-block__description {
    font-size: 1rem;
  }
}