@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
body.modal-active {
  overflow: hidden;
}

.modal-block {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100dvh;
  z-index: 10;
}
.modal-block__inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 40px 0 0;
  box-sizing: border-box;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .modal-block__inner {
    display: block;
    padding: 48px 0;
  }
}
.modal-block__popup {
  display: inline-block;
  position: relative;
  max-height: 100%;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}
.modal-block__popup-header {
  display: flex;
  justify-content: flex-end;
  height: 64px;
  padding: 12px 12px 0 16px;
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .modal-block__popup-header {
    padding: 12px 16px 0;
  }
}
.modal-block__popup-header-close-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f2f2f2;
}
.modal-block__popup-header-close-btn .icon {
  display: inline-block;
  vertical-align: middle;
  width: 12px;
  height: 12px;
}
.modal-block__popup-header-close-btn .icon-close {
  background-image: url("../../assets/images/icons/close.svg");
}
.modal-block__popup-content {
  display: flex;
  max-height: calc(100dvh - 64px - 40px);
  padding: 0 16px 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #4e5461;
  font-size: 12px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.24px;
}
@media only screen and (min-width: 768px) {
  .modal-block__popup-content {
    max-height: calc(100dvh - 160px);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
.modal-block__popup-content p {
  margin-bottom: 8px;
}
.modal-block__popup-content ol li, .modal-block__popup-content ul li {
  margin-bottom: 8px;
}
.modal-block__popup-content-outer {
  position: relative;
  width: 100%;
}
.modal-block--has-fade .modal-block__popup-content-outer:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=0 );
}
.modal-block--max-scroll .modal-block__popup-content-outer:after, .modal-block--contact-form .modal-block__popup-content-outer:after {
  display: none;
}
.modal-block__popup-content-control {
  max-height: 100%;
  overflow-y: auto;
}
.modal-block__popup-content-control::-webkit-scrollbar {
  width: 2px;
}
.modal-block__popup-content-control::-webkit-scrollbar-track {
  background: transparent;
}
.modal-block__popup-content-control::-webkit-scrollbar-thumb {
  background: #e7e7e7;
}
.modal-block__popup-content-control::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal-block--has-scroll .modal-block__popup-content-control {
  padding-right: 16px;
}
.modal-block__overlay {
  background: rgba(15, 27, 42, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(8px);
}