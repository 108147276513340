@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
.find-us-block {
  position: relative;
  padding: 24px 0 56px;
  background-color: #DBE5F0;
  overflow: hidden;
}
@media only screen and (min-width: 1025px) {
  .find-us-block {
    padding: 88px 0 116px;
  }
}
.find-us-block .container {
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 24px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .find-us-block .container {
    padding: 0 32px;
  }
}
@media only screen and (min-width: 1025px) {
  .find-us-block .container {
    padding: 0 48px;
  }
}
.find-us-block .content-row {
  margin-bottom: 48px;
}
@media only screen and (min-width: 768px) {
  .find-us-block .content-row {
    margin-bottom: 64px;
  }
}
.find-us-block .content-row:first-child {
  margin-bottom: calc(((100vw - 1024px) * 100 / 704 / 100 * -1282 - 1670px) * -1);
}
@media only screen and (min-width: 1025px) {
  .find-us-block .content-row:first-child {
    margin-bottom: 57px;
  }
}
.find-us-block .content-row:last-child {
  margin-bottom: 0;
}
.find-us-block__large-text {
  color: #2e3436;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-style: italic;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 8px;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__large-text {
    font-size: 2.5rem;
    line-height: 100%;
  }
}
.find-us-block__heading {
  color: #3c8bd2;
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 16px;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__heading {
    font-size: 4rem;
  }
}
.find-us-block__description {
  color: #2e3436;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 160%;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__description {
    font-size: 0.875rem;
  }
}
.find-us-block__partners-heading, .find-us-block__useful-links-heading, .find-us-block__get-in-touch-heading {
  color: #bbbbbb;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 24px;
  color: #2e3436;
}
@media only screen and (min-width: 768px) {
  .find-us-block__partners-heading, .find-us-block__useful-links-heading, .find-us-block__get-in-touch-heading {
    font-size: 2rem;
  }
}
.find-us-block__partners-logos {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 440px;
  box-sizing: border-box;
  border-left: 1px solid #2e3436;
  border-top: 1px solid #2e3436;
}
@media only screen and (min-width: 768px) {
  .find-us-block__partners-logos {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1025px) {
  .find-us-block__partners-logos {
    max-width: 440px;
  }
}
.find-us-block__partners-logos-item {
  grid-column: span 1;
  height: 96px;
  border-right: 1px solid #2e3436;
  border-bottom: 1px solid #2e3436;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
}
@media only screen and (min-width: 768px) {
  .find-us-block__partners-logos-item {
    height: 120px;
  }
}
.find-us-block__useful-links-list {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
}
@media only screen and (min-width: 768px) {
  .find-us-block__useful-links-list {
    align-items: center;
    flex-direction: row;
  }
}
.find-us-block__useful-links-item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
}
.find-us-block__useful-links-item img {
  width: 40px;
  height: 40px;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__useful-links-item img {
    width: 48px;
    height: 48px;
  }
}
.find-us-block__useful-links-item-title {
  color: #2e3436;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
}
.find-us-block__get-in-touch-sub-text {
  margin-bottom: 28px;
  color: #4e5461;
}
.find-us-block__get-in-touch-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 32px;
  margin: 0 0 32px;
  padding: 0 0 16px;
}
.find-us-block__get-in-touch-list > li {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .find-us-block__get-in-touch-list > li {
    width: unset;
  }
}
@media only screen and (min-width: 768px) {
  .find-us-block__get-in-touch-list {
    flex-direction: row;
    column-gap: 40px;
    padding-bottom: unset;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) {
  .find-us-block__get-in-touch-list {
    column-gap: 48px;
  }
}
.find-us-block__get-in-touch-item {
  display: inline-flex;
  align-items: center;
  column-gap: 12px;
  color: #4e5461;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
}
@media only screen and (min-width: 768px) {
  .find-us-block__get-in-touch-item {
    font-size: 1rem;
  }
}
.find-us-block__get-in-touch-item--phone span {
  white-space: nowrap;
}
.find-us-block .find-us-block__get-in-touch-contact-us-btn {
  height: 56px;
}
.find-us-block .find-us-block__get-in-touch-contact-us-btn .button-block__label {
  font-size: 0.875rem;
}
.find-us-block__map {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 1181px;
  text-align: right;
}
.find-us-block__map-bg {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/map/bg-mobile.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__map-bg {
    background-image: url("./images/map/bg.svg");
    background-size: auto 1181px;
    background-position: right top;
  }
}
@media only screen and (min-width: 1512px) {
  .find-us-block__map-bg {
    width: calc(50% + 554.5px);
  }
}
.find-us-block__map-pin {
  position: absolute;
  width: 1px;
  height: 1px;
}
.find-us-block__map-pin-btn {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 5;
  display: inline-flex;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
}
.find-us-block__map-pin-btn .icon-pin {
  display: inline-block;
  vertical-align: top;
  background-image: url("./images/map/pin.icon.svg");
  background-repeat: no-repeat;
  background-position: center bottom;
  width: 32px;
  height: 40px;
}
.find-us-block__map-pin-btn .ripple {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%) rotateX(75deg);
}
.find-us-block__map-pin--active .find-us-block__map-pin-btn {
  padding-bottom: 24px;
  margin-bottom: -24px;
  animation: bounce 400ms ease forwards;
}
.find-us-block__map-pin--active .find-us-block__map-pin-btn .ripple {
  display: block;
}
.find-us-block__map-pin--active .find-us-block__map-pin-btn .icon-pin {
  background-image: url("./images/map/pin-active.icon.svg");
  width: 48px;
  height: 56px;
}
.find-us-block__map-pin-btn-location {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  box-sizing: border-box;
  background: rgba(60, 139, 210, 0.7);
  padding: 16px 24px 16px;
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.02rem;
  border-radius: 2px;
}
.find-us-block__map-pin--active .find-us-block__map-pin-btn-location {
  display: inline-block;
}
@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .find-us-block__map-pin--british-virgin-islands .find-us-block__map-pin-btn-location {
    left: 50%;
    transform: translate(-80%, -100%);
  }
  .find-us-block__map-pin--british-virgin-islands .find-us-block__map-pin-btn-location:after {
    left: 80%;
  }
}
.find-us-block__map-pin-btn-location span {
  white-space: nowrap;
}
.find-us-block__map-pin-btn-location:before {
  content: "";
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  width: 32px;
  height: 32px;
  margin: 0 auto 8px;
}
.find-us-block__map-pin--canada .find-us-block__map-pin-btn-location:before {
  background-image: url("./images/map/countries/canada.icon.svg");
}
.find-us-block__map-pin--cayman-islands .find-us-block__map-pin-btn-location:before {
  background-image: url("./images/map/countries/cayman-islands.icon.svg");
}
.find-us-block__map-pin--british-virgin-islands .find-us-block__map-pin-btn-location:before {
  background-image: url("./images/map/countries/british-virgin-islands.icon.svg");
}
.find-us-block__map-pin-btn-location:after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4px 6.5px 0 6.5px;
  border-color: rgba(60, 139, 210, 0.7) transparent transparent transparent;
  transform: rotate(0deg) translate(-50%, 100%);
}
.find-us-block__map-pin--canada {
  right: 54%;
  top: 92.4vw;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__map-pin--canada {
    right: 291px;
    top: 203px;
  }
}
.find-us-block__map-pin--cayman-islands {
  right: 71.5%;
  top: 163.5vw;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__map-pin--cayman-islands {
    right: 370px;
    top: 610px;
  }
}
.find-us-block__map-pin--british-virgin-islands {
  right: 28.5%;
  top: 164vw;
}
@media only screen and (min-width: 1025px) {
  .find-us-block__map-pin--british-virgin-islands {
    right: 167px;
    top: 623px;
  }
}