@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
body {
  padding: 0;
  margin: 0;
  font-family: "Ubuntu", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input[type=text],
textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
}
@supports (-webkit-touch-callout: none) {
  input[type=text],
  textarea {
    outline-offset: -0.1875rem;
    outline-width: 0.125rem;
  }
}
input[type=text]::placeholder,
textarea::placeholder {
  color: #a4a4a4;
  font-size: 1rem;
  font-family: "Ubuntu", sans-serif;
}

input[type=text] {
  height: 36px;
  padding: 0 12px;
}
@media only screen and (min-width: 768px) {
  input[type=text] {
    height: 56px;
  }
}

textarea {
  height: 130px;
  padding: 12px;
  resize: none;
}
@media only screen and (min-width: 768px) {
  textarea {
    height: 209px;
  }
}

.lds-ring {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 16px;
  height: 16px;
}

.lds-ring span {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
ol,
ul {
  margin: 0;
  padding: 0 0 0 24px;
}

.dd-number-list {
  counter-reset: item;
  counter-reset: listStyle;
}
.dd-number-list > dt {
  counter-increment: item;
  counter-increment: listStyle;
  margin-bottom: 16px;
}
.dd-number-list > dt:not(:first-child) {
  margin-top: 16px;
}
.dd-number-list > dt:before {
  content: counter(listStyle, upper-alpha) ". ";
}
.dd-number-list > dd {
  counter-increment: item;
  margin-left: 16px;
  margin-bottom: 8px;
}
.dd-number-list > dd:before {
  content: counters(item, ".") ". ";
}

@keyframes bounce {
  0% {
    transform: translate(-50%, 0);
  }
  20% {
    transform: translate(-50%, 0);
  }
  70% {
    transform: translate(-50%, 3px);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
.scroll-animate {
  transition: all 800ms ease;
  opacity: 0;
}
.scroll-animate__left-origin {
  transform: translateX(-50%);
}
.scroll-animate__right-origin {
  transform: translateX(50%);
}
.scroll-animate__bottom-origin {
  transform: translateY(50%);
}

.section-visible .scroll-animate__left-origin {
  transform: translateX(0);
  opacity: 1;
}
.section-visible .scroll-animate__right-origin {
  transform: translateX(0);
  opacity: 1;
}
.section-visible .scroll-animate__bottom-origin {
  transform: translateY(0);
  opacity: 1;
}

@keyframes parallax1 {
  0% {
    left: 100%;
    top: 100%;
  }
  20% {
    left: 0%;
    top: 50%;
  }
  40% {
    left: 100%;
    top: 0%;
  }
  60% {
    left: 30%;
    top: 100%;
  }
  80% {
    left: 0%;
    top: 70%;
  }
  100% {
    left: 100%;
    top: 0%;
  }
}
@keyframes parallax4 {
  0% {
    left: 0%;
    top: 0%;
  }
  20% {
    left: 100%;
    top: 20%;
  }
  40% {
    left: 80%;
    top: 100%;
  }
  60% {
    left: 70%;
    top: 0%;
  }
  80% {
    left: 40%;
    top: 100%;
  }
  100% {
    left: 0%;
    top: 0%;
  }
}
.ripple {
  position: relative;
  width: 45px;
  height: 45px;
  transform: rotateX(75deg);
}
.ripple-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 1px solid #585D5E;
  border-radius: 999px;
  animation: pulse 2s ease-out infinite;
}
.ripple-2 {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border: 1px solid #585D5E;
  border-radius: 999px;
  animation: pulse 2s 300ms ease-out infinite;
}
.ripple-3 {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 60%;
  background-color: #585D5E;
  border-radius: 999px;
}

@keyframes pulse {
  100% {
    transform: translate(-50%, -50%) scale(1.5);
    opacity: 0;
  }
}