@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
.trade-and-invest-block {
  height: 640px;
  background: #3c8bd2;
  position: relative;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  .trade-and-invest-block {
    height: 550px;
  }
}
@media only screen and (min-width: 1025px) {
  .trade-and-invest-block {
    height: 640px;
  }
}
.trade-and-invest-block::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("./images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  pointer-events: none;
}
.trade-and-invest-block .container {
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 24px;
  position: relative;
}
@media only screen and (min-width: 768px) {
  .trade-and-invest-block .container {
    padding: 0 32px;
  }
}
@media only screen and (min-width: 1025px) {
  .trade-and-invest-block .container {
    padding: 0 48px;
  }
}
.trade-and-invest-block__intro {
  max-width: 982px;
}
.trade-and-invest-block__large-text {
  font-style: italic;
  font-weight: 400;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  margin-bottom: 12px;
}
@media only screen and (min-width: 1025px) {
  .trade-and-invest-block__large-text {
    font-size: 2.5rem;
    margin-bottom: unset;
  }
}
.trade-and-invest-block__heading {
  font-weight: 700;
  line-height: 100%;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-bottom: 24px;
  font-size: 3rem;
}
@media only screen and (min-width: 768px) {
  .trade-and-invest-block__heading {
    font-size: 3.5rem;
  }
}
@media only screen and (min-width: 1025px) {
  .trade-and-invest-block__heading {
    font-size: 4rem;
  }
}
.trade-and-invest-block__description {
  font-weight: 500;
  line-height: 160%;
  font-size: 0.75rem;
}
@media only screen and (min-width: 768px) {
  .trade-and-invest-block__description {
    font-size: 0.875rem;
  }
}