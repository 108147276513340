@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Open+Sans:wght@400;700&family=Ubuntu&display=swap");
.header-block {
  background-color: #081e30;
  height: 64px;
}
.header-block .container {
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 24px;
  max-width: unset;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
@media only screen and (min-width: 768px) {
  .header-block .container {
    padding: 0 32px;
  }
}
@media only screen and (min-width: 1025px) {
  .header-block .container {
    padding: 0 48px;
  }
}
.header-block__logo {
  text-decoration: none;
}